<template>
  <div>
    <Page :tabList="tabList" :getPageList="getPageList"></Page>
  </div>
</template>
  
  <script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tabList: [],
      getPageList: [
        {
          name: "文件学习",
          path: "/file-learn",
        },
        {
          name: "专题学习",
          path: "/special-learn",
        },
        {
          name: "正式业务测评",
          path: "/formal",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
  
  <style lang="less" scoped >
</style>